export const USER_ROLE_TYPE = {
  SuperAdmin: 1,
  Admin: 2,
};

export const CLIENT_TYPES = {
  Other: null,
  Individual: 1,
  Sole_Trader: 2,
  Partnership: 3,
  LLP: 4,
  Company: 5,
};

export const ServiceHeader = {
  BasicInformation: 1,
  Description: 2,
  PricingDrivers: 3,
  PricingFormula: 4,
};
export const CREATE_PRACTICE_DETAILS = {
  BasicInformation: 1,
  OfficerDetails: 2,
  OtherInformation: 3,
  ChoosePlan: 4,
};
export const EMAIL_TEMPLATE = {
  All_Variables: null,
  Quote: 5,
  Contract: 6,
  Email_Invite: 7,
  Quote_PDF: 8,
  Contract_Viewed: 9,
  Contract_Accepted: 10,
  Contract_Declined: 11,
  Quote_Accepted: 17,
  Quote_Declined: 18,
  Email_Invite_For_Organisation: 19,
  Quote_AcceptedDeclined_Email_Send_To_Sender: 20,
  Contract_Accepted_Email_Send_To_Sender: 21,
  Contract_Declined_Email_Send_To_Receiver: 22,
};
export const SUPER_EMAIL_TEMPLATE = {
  SuperAllVariable: null,
  Confirmation_Email: 13,
  Welcome_Email: 14,
  Forget_Password_Email: 15,
  Invite_For_Sign_UP_Email: 12,
  Two_FA: 16,
};
export const Template_Type = {
  Quote: 1,
  Contract: 2,
};

export const VAT_Reg = {
  Yes: 1,
  No: 0,
};

export const PackageHeader = {
  BasicInformation: 1,
  SelectServices: 2,
  AdditionalInformation: 3,
  PricingInformation: 4,
};

export const EngagementLetterHeader = {
  BasicInformation: 1,
  SelectServices: 2,
  AdditionalInformation: 3,
  ReviewServices: 4,
  Preview: 5,
};
export const Payment_Frequency = {
  Yearly: 1,
  HalfYearly: 2,
  Quarterly: 3,
  Monthly: 4,
};
export const ProposalHeader = {
  BasicInformation: 1,
  SelectServices: 2,
  AdditionalInformation: 3,
  Preview: 4,
  SelectPackages: 5,
  ReviewServices: 6,
  ReviewPackages: 7,
};

export const ElementType = {
  HEADING: 1,
  TEXT_BLOCK: 2,
  SERVICE_PRICING_TABLE: 3,
  SERVICE_DESCRIPTION: 4,
  FULL_PAGE_HEADING: 5,
  PAGE_BREAK: 6,
  SIGNATURE_BLOCK: 7,
  STATEMENT_OF_FACTS: 8,
  AWS_PDF_LINK: 9,
  First_Page: 10,
};

export const TwoFactor = {
  BasicInformation: 1,
  ShowQrCode: 2,
  AuthenticatorCode: 3,
  RegisterEmail: 4,
  EmailVerification: 5,
};

export const ServiceChargeTypeEnum = {
  Recurring: 1,
  OneOff: 2,
};

export const statusID = {
  Draft: 1,
  Sent: 2,
  Skipped: 3,
  Awaiting_Signature: 4,
  Signed: 5,
  Accepted: 6,
  Declined: 7,
  All: 8,
};
export const statusNames = {
  Draft: "draft",
  Sent: "sent",
  Skipped: "skipped",
  Awaiting_Signature: "Awaiting_Signature",
  Signed: "completed",
  Accepted: "accepted",
  Declined: "declined",
  All: "all",
  Completed: "completed",
  Pending: "pending"
};

export const CalenderFilterEnum = {
  All: 0,
  This_Week: 1,
  Last_Week: 2,
  This_Month: 3,
  Last_Month: 4,
  This_Quarter: 5,
  Last_Quarter: 6,
  This_6_Months: 7,
  Last_6_Months: 8,
  This_Year: 9,
  Last_Year: 10,
  Custom_Date_Range: 11,
};

const GetReplacePackageTableView = (
  RecurringPricingInfo,
  Type,
  servicePackageList
) => {
  let PackageOneValue = null;
  let PackageTwoValue = null;
  let PackageThreeValue = null;

  if (Type === "Net Total") {
    PackageOneValue = Number(
      RecurringPricingInfo.packageOneNetTotal >
      Number(RecurringPricingInfo.packageOneDisCountedTotal)
    )
      ? RecurringPricingInfo.packageOneNetTotal
      : RecurringPricingInfo.packageOneDisCountedTotal;
    PackageTwoValue = Number(
      RecurringPricingInfo.packageTwoNetTotal >
      Number(RecurringPricingInfo.packageTwoDisCountedTotal)
    )
      ? RecurringPricingInfo.packageTwoNetTotal
      : RecurringPricingInfo.packageTwoDisCountedTotal;
    PackageThreeValue = Number(
      RecurringPricingInfo.packageThreeNetTotal >
      Number(RecurringPricingInfo.packageThreeDisCountedTotal)
    )
      ? RecurringPricingInfo.packageThreeNetTotal
      : RecurringPricingInfo.packageThreeDisCountedTotal;
  } else if (Type === "Discount") {
    PackageOneValue = RecurringPricingInfo.packageOneDisCount;
    PackageTwoValue = RecurringPricingInfo.packageTwoDisCount;
    PackageThreeValue = RecurringPricingInfo.packageThreeDisCount;
  } else if (Type === "Discounted Total") {
    PackageOneValue = RecurringPricingInfo.packageOneDisCountedTotal;
    PackageTwoValue = RecurringPricingInfo.packageTwoDisCountedTotal;
    PackageThreeValue = RecurringPricingInfo.packageThreeDisCountedTotal;
  } else if (Type === "VAT") {
    PackageOneValue = RecurringPricingInfo.PackageOneVaTPrice;
    PackageTwoValue = RecurringPricingInfo.PackageTwoVaTPrice;
    PackageThreeValue = RecurringPricingInfo.PackageThreeVaTPrice;
  } else if (Type === "Grand Total") {
    PackageOneValue = RecurringPricingInfo.PackageOneGrandTotal;
    PackageTwoValue = RecurringPricingInfo.PackageTwoGrandTotal;
    PackageThreeValue = RecurringPricingInfo.PackageThreeGrandTotal;
  } else if (Type === "Original Price") {
    PackageOneValue = RecurringPricingInfo.packageOneNetTotal;
    PackageTwoValue = RecurringPricingInfo.packageTwoNetTotal;
    PackageThreeValue = RecurringPricingInfo.packageThreeNetTotal;
  } else if (Type === "Default Percentage") {
    PackageOneValue = RecurringPricingInfo.DiscountPercentagePackageOne;
    PackageTwoValue = RecurringPricingInfo.DiscountPercentagePackageTwo;
    PackageThreeValue = RecurringPricingInfo.DiscountPercentagePackageThree;
  } else if (Type === "Discounted Price") {
    PackageOneValue = RecurringPricingInfo.packageOneDisCountedTotal;
    PackageTwoValue = RecurringPricingInfo.packageTwoDisCountedTotal;
    PackageThreeValue = RecurringPricingInfo.packageThreeDisCountedTotal;
  }

  const headers = servicePackageList
    .map(
      (item) =>
        `<th style="border: 1px solid black; padding: 8px;">${item.servicePackageName}</th>`
    )
    .join("");

  let rowValues = "";
  if (servicePackageList.length === 1) {
    rowValues = `<td style="border: 1px solid black; padding: 8px;text-align:center;">${formatValue(
      PackageOneValue
    )}</td>`;
  } else if (servicePackageList.length === 2) {
    rowValues = `
      <td style="border: 1px solid black; padding: 8px;text-align:center;">${formatValue(
      PackageOneValue
    )}</td>
      <td style="border: 1px solid black; padding: 8px;text-align:center;">${formatValue(
      PackageTwoValue
    )}</td>
    `;
  } else if (servicePackageList.length === 3) {
    rowValues = `
      <td style="border: 1px solid black; padding: 8px;text-align:center;">${formatValue(
      PackageOneValue
    )}</td>
      <td style="border: 1px solid black; padding: 8px;text-align:center;">${formatValue(
      PackageTwoValue
    )}</td>
      <td style="border: 1px solid black; padding: 8px;text-align:center;">${formatValue(
      PackageThreeValue
    )}</td>
    `;
  }
  return `
    <table style="border-collapse: collapse; width: 100%; page-break-inside: avoid; break-inside: avoid;">
      <tr>
        <th style="border: 1px solid black; padding: 8px;">Package Name</th>
        ${headers}
      </tr>
      <tr>
        <th style="border: 1px solid black; padding: 8px;">${Type}</th>
        ${rowValues}
      </tr>
    </table>`;
};

const GetReplacePackageCombinedTableView = (
  RecurringPricingInfo,
  OneOffPricingInfo,
  Type,
  servicePackageList
) => {
  let PackageOneValue = null;
  let PackageTwoValue = null;
  let PackageThreeValue = null;

  let PackageOneOneOffValue = null;
  let PackageTwoOneOffValue = null;
  let PackageThreeOneOffValue = null;

  if (Type === "Net Total") {
    PackageOneValue = Number(
      RecurringPricingInfo.packageOneNetTotal) >
      Number(RecurringPricingInfo.packageOneDisCountedTotal)
      ? RecurringPricingInfo.packageOneNetTotal
      : RecurringPricingInfo.packageOneDisCountedTotal;

    PackageTwoValue = Number(
      RecurringPricingInfo.packageTwoNetTotal) >
      Number(RecurringPricingInfo.packageTwoDisCountedTotal)
      ? RecurringPricingInfo.packageTwoNetTotal
      : RecurringPricingInfo.packageTwoDisCountedTotal;

    PackageThreeValue = Number(
      RecurringPricingInfo.packageThreeNetTotal) >
      Number(RecurringPricingInfo.packageThreeDisCountedTotal)
      ? RecurringPricingInfo.packageThreeNetTotal
      : RecurringPricingInfo.packageThreeDisCountedTotal;


    PackageOneOneOffValue = Number(
      OneOffPricingInfo.packageOneNetTotal) >
      Number(OneOffPricingInfo.packageOneDisCountedTotal)
      ? OneOffPricingInfo.packageOneNetTotal
      : OneOffPricingInfo.packageOneDisCountedTotal;

    PackageTwoOneOffValue = Number(
      OneOffPricingInfo.packageTwoNetTotal) >
      Number(OneOffPricingInfo.packageTwoDisCountedTotal)
      ? OneOffPricingInfo.packageTwoNetTotal
      : OneOffPricingInfo.packageTwoDisCountedTotal;

    PackageThreeOneOffValue = Number(
      OneOffPricingInfo.packageThreeNetTotal) >
      Number(OneOffPricingInfo.packageThreeDisCountedTotal)
      ? OneOffPricingInfo.packageThreeNetTotal
      : RecurringPricingInfo.packageThreeDisCountedTotal;
  } else if (Type === "Discount") {
    PackageOneValue = RecurringPricingInfo.packageOneDisCount;
    PackageTwoValue = RecurringPricingInfo.packageTwoDisCount;
    PackageThreeValue = RecurringPricingInfo.packageThreeDisCount;

    PackageOneOneOffValue = OneOffPricingInfo.packageOneDisCount;
    PackageTwoOneOffValue = OneOffPricingInfo.packageTwoDisCount;
    PackageThreeOneOffValue = OneOffPricingInfo.packageThreeDisCount;
  } else if (Type === "Discounted Total") {
    PackageOneValue = RecurringPricingInfo.packageOneDisCountedTotal;
    PackageTwoValue = RecurringPricingInfo.packageTwoDisCountedTotal;
    PackageThreeValue = RecurringPricingInfo.packageThreeDisCountedTotal;

    PackageOneOneOffValue = OneOffPricingInfo.packageOneDisCountedTotal;
    PackageTwoOneOffValue = OneOffPricingInfo.packageTwoDisCountedTotal;
    PackageThreeOneOffValue = OneOffPricingInfo.packageThreeDisCountedTotal;
  } else if (Type === "VAT") {
    PackageOneValue = RecurringPricingInfo.PackageOneVaTPrice;
    PackageTwoValue = RecurringPricingInfo.PackageTwoVaTPrice;
    PackageThreeValue = RecurringPricingInfo.PackageThreeVaTPrice;

    PackageOneOneOffValue = OneOffPricingInfo.PackageOneVaTPrice;
    PackageTwoOneOffValue = OneOffPricingInfo.PackageTwoVaTPrice;
    PackageThreeOneOffValue = OneOffPricingInfo.PackageThreeVaTPrice;
  } else if (Type === "Grand Total") {
    PackageOneValue = RecurringPricingInfo.PackageOneGrandTotal;
    PackageTwoValue = RecurringPricingInfo.PackageTwoGrandTotal;
    PackageThreeValue = RecurringPricingInfo.PackageThreeGrandTotal;

    PackageOneOneOffValue = OneOffPricingInfo.PackageOneGrandTotal;
    PackageTwoOneOffValue = OneOffPricingInfo.PackageTwoGrandTotal;
    PackageThreeOneOffValue = OneOffPricingInfo.PackageThreeGrandTotal;
  } else if (Type === "Original Price") {
    PackageOneValue = RecurringPricingInfo.packageOneNetTotal;
    PackageTwoValue = RecurringPricingInfo.packageTwoNetTotal;
    PackageThreeValue = RecurringPricingInfo.packageThreeNetTotal;

    PackageOneOneOffValue = OneOffPricingInfo.packageOneNetTotal;
    PackageTwoOneOffValue = OneOffPricingInfo.packageTwoNetTotal;
    PackageThreeOneOffValue = OneOffPricingInfo.packageThreeNetTotal;
  } else if (Type === "Default Percentage") {
    PackageOneValue = RecurringPricingInfo.DiscountPercentagePackageOne;
    PackageTwoValue = RecurringPricingInfo.DiscountPercentagePackageTwo;
    PackageThreeValue = RecurringPricingInfo.DiscountPercentagePackageThree;

    PackageOneOneOffValue = OneOffPricingInfo.DiscountPercentagePackageOne;
    PackageTwoOneOffValue = OneOffPricingInfo.DiscountPercentagePackageTwo;
    PackageThreeOneOffValue = OneOffPricingInfo.DiscountPercentagePackageThree;
  } else if (Type === "Discounted Price") {
    PackageOneValue = RecurringPricingInfo.packageOneDisCountedTotal;
    PackageTwoValue = RecurringPricingInfo.packageTwoDisCountedTotal;
    PackageThreeValue = RecurringPricingInfo.packageThreeDisCountedTotal;

    PackageOneOneOffValue = OneOffPricingInfo.packageOneDisCountedTotal;
    PackageTwoOneOffValue = OneOffPricingInfo.packageTwoDisCountedTotal;
    PackageThreeOneOffValue = OneOffPricingInfo.packageThreeDisCountedTotal;
  }

  const headers = servicePackageList
    .map(
      (item) =>
        `<th style="border: 1px solid black; padding: 8px;">${item.servicePackageName}</th>`
    )
    .join("");

  let rowValues = "";
  if (servicePackageList.length === 1) {
    rowValues = `<td style="border: 1px solid black; padding: 8px;"> <ul>
        <li>Recurring: ${formatValue(PackageOneValue)}</li>
        <li>One Off: ${formatValue(PackageOneOneOffValue)}</li>
      </ul></td>`;
  } else if (servicePackageList.length === 2) {
    rowValues = `
      <td style="border: 1px solid black; padding: 8px;"><ul>
        <li>Recurring: ${formatValue(PackageOneValue)}</li>
        <li>One Off: ${formatValue(PackageOneOneOffValue)}</li>
      </ul></td>
      <td style="border: 1px solid black; padding: 8px;"><ul>
        <li>Recurring: ${formatValue(PackageTwoValue)}</li>
        <li>One Off: ${formatValue(PackageTwoOneOffValue)}</li>
      </ul></td>
    `;
  } else if (servicePackageList.length === 3) {
    rowValues = `
      <td style="border: 1px solid black; padding: 8px;"><ul>
        <li>Recurring: ${formatValue(PackageOneValue)}</li>
        <li>One Off: ${formatValue(PackageOneOneOffValue)}</li>
      </ul></td>
      <td style="border: 1px solid black; padding: 8px;"><ul>
        <li>Recurring: ${formatValue(PackageTwoValue)}</li>
        <li>One Off: ${formatValue(PackageTwoOneOffValue)}</li>
      </ul></td>
      <td style="border: 1px solid black; padding: 8px;"><ul>
        <li>Recurring: ${formatValue(PackageThreeValue)}</li>
        <li>One Off: ${formatValue(PackageThreeOneOffValue)}</li>
      </ul></td>
    `;
  }

  return `
    <table style="border-collapse: collapse; width: 100%; page-break-inside: avoid; break-inside: avoid;">
      <tr>
        <th style="border: 1px solid black; padding: 8px;">Package Name</th>
        ${headers}
      </tr>
      <tr>
        <th style="border: 1px solid black; padding: 8px;">${Type}</th>
        ${rowValues}
      </tr>
    </table>`;
};

// const GetReplacePackageCombinedTableViewForSinglePackage = (
//   RecurringValue,
//   OneOffValue,
//   Type,
//   servicePackageList
// ) => {

//   if (servicePackageList?.length > 0) {
//     const headers = servicePackageList
//       .map(
//         (item) =>
//           `<th style="border: 1px solid black; padding: 8px;">${item.servicePackageName}</th>`
//       )
//       .join("");

//     let rowValues = "";
//     if (servicePackageList.length === 1) {
//       rowValues = `<td style="border: 1px solid black; padding: 8px;"> <ul>
//         <li>Recurring: ${formatValue(RecurringValue)}</li>
//         <li>One Off: ${formatValue(OneOffValue)}</li>
//       </ul></td>`;
//     }

//     return `
//     <table style="border-collapse: collapse; width: 100%; page-break-inside: avoid; break-inside: avoid;">
//       <tr>
//         <th style="border: 1px solid black; padding: 8px;">Package Name</th>
//         ${headers}
//       </tr>
//       <tr>
//         <th style="border: 1px solid black; padding: 8px;">${Type}</th>
//         ${rowValues}
//       </tr>
//     </table>`;
//   } else {
//     return `Recurring: ${formatValue(RecurringValue)}, One Off: ${formatValue(
//       OneOffValue
//     )}`;
//   }

// };
function formatValue(value) {
  value = value == null ? 0 : value;
  //console.log("props.RecurringPricingInfo.OriginalPrice : ", props.RecurringPricingInfo.OriginalPrice)
  let valueLength = 0;
  // if (value(".")) {
  let valueArray = value?.toString().split(".");
  //}

  if (valueArray.length >= 1) {
    valueLength = Number(valueArray[1]);
  }

  let valueWithExactTwoPrecision = value.toString();
  if (valueLength.toString().length > 2) {
    valueWithExactTwoPrecision = (Math.floor(value * 100) / 100).toFixed(2);
  }

  return `${Number(valueWithExactTwoPrecision)
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
}

const GetReplaceValueByWithComma = (
  RecurringValue,
  OneOffValue,
  selectedProposalTypeValue,
  servicePackageList,
  Type
) => {
  if (selectedProposalTypeValue === 3) {
    return `Recurring: ${formatValue(RecurringValue)}, One Off: ${formatValue(
      OneOffValue
    )}`;
  } else {
    let PackageOneValue = ""
    let PackageTwoValue = ""
    let PackageThreeValue = ""
    let PackageOneOneOffValue = ""
    let PackageTwoOneOffValue = ""
    let PackageThreeOneOffValue = ""
    if (Type === "Net Total") {
      PackageOneValue = Number(
        RecurringValue.packageOneNetTotal >
        Number(RecurringValue.packageOneDisCountedTotal)
      )
        ? RecurringValue.packageOneNetTotal
        : RecurringValue.packageOneDisCountedTotal;
      PackageTwoValue = Number(
        RecurringValue.packageTwoNetTotal >
        Number(RecurringValue.packageTwoDisCountedTotal)
      )
        ? RecurringValue.packageTwoNetTotal
        : RecurringValue.packageTwoDisCountedTotal;
      PackageThreeValue = Number(
        RecurringValue.packageThreeNetTotal >
        Number(RecurringValue.packageThreeDisCountedTotal)
      )
        ? RecurringValue.packageThreeNetTotal
        : RecurringValue.packageThreeDisCountedTotal;



      PackageOneOneOffValue = Number(
        OneOffValue.packageOneNetTotal >
        Number(OneOffValue.packageOneDisCountedTotal)
      )
        ? OneOffValue.packageOneNetTotal
        : OneOffValue.packageOneDisCountedTotal;
      PackageTwoOneOffValue = Number(
        OneOffValue.packageTwoNetTotal >
        Number(OneOffValue.packageTwoDisCountedTotal)
      )
        ? OneOffValue.packageTwoNetTotal
        : OneOffValue.packageTwoDisCountedTotal;
      PackageThreeOneOffValue = Number(
        OneOffValue.packageThreeNetTotal >
        Number(OneOffValue.packageThreeDisCountedTotal)
      )
        ? OneOffValue.packageThreeNetTotal
        : OneOffValue.packageThreeDisCountedTotal;
    } else if (Type === "Discount") {
      PackageOneValue = RecurringValue.packageOneDisCount;
      PackageTwoValue = RecurringValue.packageTwoDisCount;
      PackageThreeValue = RecurringValue.packageThreeDisCount;

      PackageOneOneOffValue = OneOffValue.packageOneDisCount;
      PackageTwoOneOffValue = OneOffValue.packageTwoDisCount;
      PackageThreeOneOffValue = OneOffValue.packageThreeDisCount;
    } else if (Type === "Discounted Total") {
      PackageOneValue = RecurringValue.packageOneDisCountedTotal;
      PackageTwoValue = RecurringValue.packageTwoDisCountedTotal;
      PackageThreeValue = RecurringValue.packageThreeDisCountedTotal;

      PackageOneOneOffValue = OneOffValue.packageOneDisCountedTotal;
      PackageTwoOneOffValue = OneOffValue.packageTwoDisCountedTotal;
      PackageThreeOneOffValue = OneOffValue.packageThreeDisCountedTotal;
    } else if (Type === "VAT") {
      PackageOneValue = RecurringValue.PackageOneVaTPrice;
      PackageTwoValue = RecurringValue.PackageTwoVaTPrice;
      PackageThreeValue = RecurringValue.PackageThreeVaTPrice;

      PackageOneOneOffValue = OneOffValue.PackageOneVaTPrice;
      PackageTwoOneOffValue = OneOffValue.PackageTwoVaTPrice;
      PackageThreeOneOffValue = OneOffValue.PackageThreeVaTPrice;
    } else if (Type === "Grand Total") {
      PackageOneValue = RecurringValue.PackageOneGrandTotal;
      PackageTwoValue = RecurringValue.PackageTwoGrandTotal;
      PackageThreeValue = RecurringValue.PackageThreeGrandTotal;

      PackageOneOneOffValue = OneOffValue.PackageOneGrandTotal;
      PackageTwoOneOffValue = OneOffValue.PackageTwoGrandTotal;
      PackageThreeOneOffValue = OneOffValue.PackageThreeGrandTotal;
    } else if (Type === "Original Price") {
      PackageOneValue = RecurringValue.packageOneNetTotal;
      PackageTwoValue = RecurringValue.packageTwoNetTotal;
      PackageThreeValue = RecurringValue.packageThreeNetTotal;

      PackageOneOneOffValue = OneOffValue.packageOneNetTotal;
      PackageTwoOneOffValue = OneOffValue.packageTwoNetTotal;
      PackageThreeOneOffValue = OneOffValue.packageThreeNetTotal;
    } else if (Type === "Default Percentage") {
      PackageOneValue = RecurringValue.DiscountPercentagePackageOne;
      PackageTwoValue = RecurringValue.DiscountPercentagePackageTwo;
      PackageThreeValue = RecurringValue.DiscountPercentagePackageThree;

      PackageOneOneOffValue = OneOffValue.DiscountPercentagePackageOne;
      PackageTwoOneOffValue = OneOffValue.DiscountPercentagePackageTwo;
      PackageThreeOneOffValue = OneOffValue.DiscountPercentagePackageThree;
    } else if (Type === "Discounted Price") {
      PackageOneValue = RecurringValue.packageOneDisCountedTotal;
      PackageTwoValue = RecurringValue.packageTwoDisCountedTotal;
      PackageThreeValue = RecurringValue.packageThreeDisCountedTotal;

      PackageOneOneOffValue = OneOffValue.packageOneDisCountedTotal;
      PackageTwoOneOffValue = OneOffValue.packageTwoDisCountedTotal;
      PackageThreeOneOffValue = OneOffValue.packageThreeDisCountedTotal;
    }


    return servicePackageList
      .map(
        (item, index) =>
          `<b>${item.servicePackageName}</b>: Recurring: ${formatValue(
            [PackageOneValue, PackageTwoValue, PackageThreeValue][index]
          )}, One Off: ${formatValue(
            [PackageOneOneOffValue, PackageTwoOneOffValue, PackageThreeOneOffValue][index]
          )}`
      )
      .join(", ");
  }
};


const GetReplaceValueByWithBulletList = (
  RecurringValue,
  OneOffValue,
  selectedProposalTypeValue,
  servicePackageList,
  Type
) => {
  if (selectedProposalTypeValue === 3) {
    return `
      <ul>
        <li>Recurring: ${formatValue(RecurringValue)}</li>
        <li>One Off: ${formatValue(OneOffValue)}</li>
      </ul>
    `;
  } else {


    let PackageOneValue = ""
    let PackageTwoValue = ""
    let PackageThreeValue = ""
    let PackageOneOneOffValue = ""
    let PackageTwoOneOffValue = ""
    let PackageThreeOneOffValue = ""
    if (Type === "Net Total") {
      PackageOneValue = Number(
        RecurringValue.packageOneNetTotal >
        Number(RecurringValue.packageOneDisCountedTotal)
      )
        ? RecurringValue.packageOneNetTotal
        : RecurringValue.packageOneDisCountedTotal;
      PackageTwoValue = Number(
        RecurringValue.packageTwoNetTotal >
        Number(RecurringValue.packageTwoDisCountedTotal)
      )
        ? RecurringValue.packageTwoNetTotal
        : RecurringValue.packageTwoDisCountedTotal;
      PackageThreeValue = Number(
        RecurringValue.packageThreeNetTotal >
        Number(RecurringValue.packageThreeDisCountedTotal)
      )
        ? RecurringValue.packageThreeNetTotal
        : RecurringValue.packageThreeDisCountedTotal;



      PackageOneOneOffValue = Number(
        OneOffValue.packageOneNetTotal >
        Number(OneOffValue.packageOneDisCountedTotal)
      )
        ? OneOffValue.packageOneNetTotal
        : OneOffValue.packageOneDisCountedTotal;
      PackageTwoOneOffValue = Number(
        OneOffValue.packageTwoNetTotal >
        Number(OneOffValue.packageTwoDisCountedTotal)
      )
        ? OneOffValue.packageTwoNetTotal
        : OneOffValue.packageTwoDisCountedTotal;
      PackageThreeOneOffValue = Number(
        OneOffValue.packageThreeNetTotal >
        Number(OneOffValue.packageThreeDisCountedTotal)
      )
        ? OneOffValue.packageThreeNetTotal
        : OneOffValue.packageThreeDisCountedTotal;
    } else if (Type === "Discount") {
      PackageOneValue = RecurringValue.packageOneDisCount;
      PackageTwoValue = RecurringValue.packageTwoDisCount;
      PackageThreeValue = RecurringValue.packageThreeDisCount;

      PackageOneOneOffValue = OneOffValue.packageOneDisCount;
      PackageTwoOneOffValue = OneOffValue.packageTwoDisCount;
      PackageThreeOneOffValue = OneOffValue.packageThreeDisCount;
    } else if (Type === "Discounted Total") {
      PackageOneValue = RecurringValue.packageOneDisCountedTotal;
      PackageTwoValue = RecurringValue.packageTwoDisCountedTotal;
      PackageThreeValue = RecurringValue.packageThreeDisCountedTotal;

      PackageOneOneOffValue = OneOffValue.packageOneDisCountedTotal;
      PackageTwoOneOffValue = OneOffValue.packageTwoDisCountedTotal;
      PackageThreeOneOffValue = OneOffValue.packageThreeDisCountedTotal;
    } else if (Type === "VAT") {
      PackageOneValue = RecurringValue.PackageOneVaTPrice;
      PackageTwoValue = RecurringValue.PackageTwoVaTPrice;
      PackageThreeValue = RecurringValue.PackageThreeVaTPrice;

      PackageOneOneOffValue = OneOffValue.PackageOneVaTPrice;
      PackageTwoOneOffValue = OneOffValue.PackageTwoVaTPrice;
      PackageThreeOneOffValue = OneOffValue.PackageThreeVaTPrice;
    } else if (Type === "Grand Total") {
      PackageOneValue = RecurringValue.PackageOneGrandTotal;
      PackageTwoValue = RecurringValue.PackageTwoGrandTotal;
      PackageThreeValue = RecurringValue.PackageThreeGrandTotal;

      PackageOneOneOffValue = OneOffValue.PackageOneGrandTotal;
      PackageTwoOneOffValue = OneOffValue.PackageTwoGrandTotal;
      PackageThreeOneOffValue = OneOffValue.PackageThreeGrandTotal;
    } else if (Type === "Original Price") {
      PackageOneValue = RecurringValue.packageOneNetTotal;
      PackageTwoValue = RecurringValue.packageTwoNetTotal;
      PackageThreeValue = RecurringValue.packageThreeNetTotal;

      PackageOneOneOffValue = OneOffValue.packageOneNetTotal;
      PackageTwoOneOffValue = OneOffValue.packageTwoNetTotal;
      PackageThreeOneOffValue = OneOffValue.packageThreeNetTotal;
    } else if (Type === "Default Percentage") {
      PackageOneValue = RecurringValue.DiscountPercentagePackageOne;
      PackageTwoValue = RecurringValue.DiscountPercentagePackageTwo;
      PackageThreeValue = RecurringValue.DiscountPercentagePackageThree;

      PackageOneOneOffValue = OneOffValue.DiscountPercentagePackageOne;
      PackageTwoOneOffValue = OneOffValue.DiscountPercentagePackageTwo;
      PackageThreeOneOffValue = OneOffValue.DiscountPercentagePackageThree;
    } else if (Type === "Discounted Price") {
      PackageOneValue = RecurringValue.packageOneDisCountedTotal;
      PackageTwoValue = RecurringValue.packageTwoDisCountedTotal;
      PackageThreeValue = RecurringValue.packageThreeDisCountedTotal;

      PackageOneOneOffValue = OneOffValue.packageOneDisCountedTotal;
      PackageTwoOneOffValue = OneOffValue.packageTwoDisCountedTotal;
      PackageThreeOneOffValue = OneOffValue.packageThreeDisCountedTotal;
    }
    return `
      <ul>
        ${servicePackageList
        .map(
          (item, index) => `
          <li>${item.servicePackageName}: Recurring: ${formatValue(
            [PackageOneValue, PackageTwoValue, PackageThreeValue][index]
          )}, One Off: ${formatValue(
            [PackageOneOneOffValue, PackageTwoOneOffValue, PackageThreeOneOffValue][index]
          )}</li>
        `
        )
        .join("")}
      </ul>
    `;
  }
};

const getPaymentFrequencyLabel = (FrequencyTypeID) => {
  const Payment_Frequency = {
    Yearly: 1,
    HalfYearly: 2,
    Quarterly: 3,
    Monthly: 4,
  };

  switch (FrequencyTypeID) {
    case Payment_Frequency.Yearly:
      return "Yearly";
    case Payment_Frequency.HalfYearly:
      return "Half-Yearly";
    case Payment_Frequency.Quarterly:
      return "Quarterly";
    case Payment_Frequency.Monthly:
      return "Monthly";
    default:
      return "Unknown";
  }
};
export function replaceTemplatePricingVariables(
  array,
  RecurringPricingInfo,
  OneOffPricingInfo,
  paymentFrequency,
  selectedProposalTypeValue,
  servicePackageList
) {
  let ResultTotalVariablesWithValues = {};
  const conditionalRecurringNetTotal =
    Number(RecurringPricingInfo.OriginalPrice) <
      Number(RecurringPricingInfo.DiscountedPrice)
      ? RecurringPricingInfo.DiscountedPrice
      : RecurringPricingInfo.OriginalPrice;
  const conditionalOneOffNetTotal =
    Number(OneOffPricingInfo.OriginalPrice) <
      Number(OneOffPricingInfo.DiscountedPrice)
      ? OneOffPricingInfo.DiscountedPrice
      : OneOffPricingInfo.OriginalPrice;

  if (selectedProposalTypeValue === 3) {
    ResultTotalVariablesWithValues = {
      Net_Total_Recurring: formatValue(conditionalRecurringNetTotal),
      Discount_Recurring: formatValue(RecurringPricingInfo.Discount),
      Discounted_Total_Recurring: formatValue(
        RecurringPricingInfo.DiscountedTotal
      ),
      VAT_Recurring: formatValue(RecurringPricingInfo.VATPrice),
      Grand_Total_Recurring: formatValue(RecurringPricingInfo.GrandTotal),
      Original_Price_Recurring: formatValue(RecurringPricingInfo.OriginalPrice),
      Discount_Percentage_Recurring: formatValue(
        RecurringPricingInfo.DefaultDiscount
      ),
      Discounted_Price_Recurring: formatValue(
        RecurringPricingInfo.DiscountedTotal
      ),
      Payment_Frequency_Recurring: getPaymentFrequencyLabel(paymentFrequency),

      Net_Total_OneOff: formatValue(conditionalOneOffNetTotal),
      Discount_OneOff: formatValue(OneOffPricingInfo.Discount),
      Discounted_Total_OneOff: formatValue(OneOffPricingInfo.DiscountedTotal),
      VAT_OneOff: formatValue(OneOffPricingInfo.VATPrice),
      Grand_Total_OneOff: formatValue(OneOffPricingInfo.GrandTotal),
      Original_Price_OneOff: formatValue(OneOffPricingInfo.OriginalPrice),
      Discount_Percentage_OneOff: formatValue(
        OneOffPricingInfo.DefaultDiscount
      ),
      Discounted_Price_OneOff: formatValue(OneOffPricingInfo.DiscountedTotal),

      Net_Total_WithTableView: GetReplaceValueByWithComma(
        conditionalRecurringNetTotal,
        conditionalOneOffNetTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discount_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.Discount,
        OneOffPricingInfo.Discount,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discounted_Total_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.DiscountedTotal,
        OneOffPricingInfo.DiscountedTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      VAT_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.VATPrice,
        OneOffPricingInfo.VATPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Grand_Total_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.GrandTotal,
        OneOffPricingInfo.GrandTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Original_Price_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.OriginalPrice,
        OneOffPricingInfo.OriginalPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discount_Percentage_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.DefaultDiscount,
        OneOffPricingInfo.DefaultDiscount,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discounted_Price_WithTableView: GetReplaceValueByWithComma(
        RecurringPricingInfo.DiscountedPrice,
        OneOffPricingInfo.DiscountedPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),

      Net_Total_WithComma: GetReplaceValueByWithComma(
        conditionalRecurringNetTotal,
        conditionalOneOffNetTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discount_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.Discount,
        OneOffPricingInfo.Discount,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discounted_Total_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.DiscountedTotal,
        OneOffPricingInfo.DiscountedTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      VAT_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.VATPrice,
        OneOffPricingInfo.VATPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Grand_Total_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.GrandTotal,
        OneOffPricingInfo.GrandTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Original_Price_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.OriginalPrice,
        OneOffPricingInfo.OriginalPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discount_Percentage_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.DefaultDiscount,
        OneOffPricingInfo.DefaultDiscount,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discounted_Price_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo.DiscountedPrice,
        OneOffPricingInfo.DiscountedPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),

      Net_Total_WithBulletList: GetReplaceValueByWithBulletList(
        conditionalRecurringNetTotal,
        conditionalOneOffNetTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discount_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.Discount,
        OneOffPricingInfo.Discount,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discounted_Total_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.DiscountedTotal,
        OneOffPricingInfo.DiscountedTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      VAT_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.VATPrice,
        OneOffPricingInfo.VATPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Grand_Total_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.GrandTotal,
        OneOffPricingInfo.GrandTotal,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Original_Price_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.OriginalPrice,
        OneOffPricingInfo.OriginalPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discount_Percentage_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.DefaultDiscount,
        OneOffPricingInfo.DefaultDiscount,
        selectedProposalTypeValue,
        servicePackageList
      ),
      Discounted_Price_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo.DiscountedPrice,
        OneOffPricingInfo.DiscountedPrice,
        selectedProposalTypeValue,
        servicePackageList
      ),
    };
  } else {
    ResultTotalVariablesWithValues = {
      Net_Total_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Net Total",
        servicePackageList
      ),
      Discount_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Discount",
        servicePackageList
      ),
      Discounted_Total_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Discounted Total",
        servicePackageList
      ),
      VAT_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "VAT",
        servicePackageList
      ),
      Grand_Total_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Grand Total",
        servicePackageList
      ),
      Original_Price_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Original Price",
        servicePackageList
      ),
      Discount_Percentage_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Default Percentage",
        servicePackageList
      ),
      Discounted_Price_Recurring: GetReplacePackageTableView(
        RecurringPricingInfo,
        "Discounted Price",
        servicePackageList
      ),
      Payment_Frequency_Recurring: getPaymentFrequencyLabel(paymentFrequency),

      Net_Total_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Net Total",
        servicePackageList
      ),
      Discount_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Discount",
        servicePackageList
      ),
      Discounted_Total_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Discounted Total",
        servicePackageList
      ),

      VAT_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "VAT",
        servicePackageList
      ),
      Grand_Total_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Grand Total",
        servicePackageList
      ),
      Original_Price_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Original Price",
        servicePackageList
      ),
      Discount_Percentage_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Default Percentage",
        servicePackageList
      ),
      Discounted_Price_OneOff: GetReplacePackageTableView(
        OneOffPricingInfo,
        "Discounted Price",
        servicePackageList
      ),
      Net_Total_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Net Total",
        servicePackageList
      ),
      Discount_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Discount",
        servicePackageList
      ),
      Discounted_Total_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Discounted Total",
        servicePackageList
      ),
      Discounted_Price_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Discounted Price",
        servicePackageList
      ),
      VAT_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "VAT",
        servicePackageList
      ),
      Grand_Total_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Grand Total",
        servicePackageList
      ),
      Original_Price_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Original Price",
        servicePackageList
      ),
      Discount_Percentage_WithTableView: GetReplacePackageCombinedTableView(
        RecurringPricingInfo,
        OneOffPricingInfo,
        "Default Percentage",
        servicePackageList
      ),

      Net_Total_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Net Total",
      ),
      Discount_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Discount",
      ),
      Discounted_Total_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList
      ),
      VAT_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "VAT"
      ),
      Grand_Total_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Grand Total"
      ),
      Original_Price_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Original Price"
      ),
      Discount_Percentage_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Default Percentage",
      ),
      Discounted_Price_WithComma: GetReplaceValueByWithComma(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Default Price",
      ),

      Net_Total_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Net Total",
      ),
      Discount_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Discount",
      ),
      Discounted_Total_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList
      ),
      VAT_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "VAT"
      ),
      Grand_Total_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Grand Total"
      ),
      Original_Price_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Original Price"
      ),
      Discount_Percentage_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Default Percentage",
      ),
      Discounted_Price_WithBulletList: GetReplaceValueByWithBulletList(
        RecurringPricingInfo,
        OneOffPricingInfo,
        selectedProposalTypeValue,
        servicePackageList,
        "Default Price",
      ),
    };
  }

  const replacedArray = array.map((item) => {
    if (item.htmlContent) {
      let replacedContent = item.htmlContent;

      // Iterate over each variable and replace it in the content
      for (const variable in ResultTotalVariablesWithValues) {
        let value =
          ResultTotalVariablesWithValues[variable] == null
            ? "0.00"
            : ResultTotalVariablesWithValues[variable];

        const regex = new RegExp(`\\$${variable}\\$`, "g");

        replacedContent = replacedContent.replace(regex, value);
      }
      // Update the htmlContent in the item
      return { ...item, htmlContent: replacedContent };
    } else {
      return item;
    }
  });

  return replacedArray;
}

// src/emailTemplates.js
export const EmailTemplates = {
  UnpaidUser_FirstMail: 31,
  UnpaidUser_SecondMail: 32,
  UnpaidUser_ThirdMail: 33,
  UnpaidUser_DeletionMail: 34,
  PaidUser_FirstMail: 35,
  PaidUser_SecondMail: 36,
  PaidUser_ThirdMail: 37,
  Reminder: 38
};
export const EmailProviderEnum = {
  BT_Internet: 1,
  Gmail: 2,
  Hotmail: 3,
  Microsoft: 4,
  Microsoft_Exchange_server: 5,
  Office_365: 6,
  Yahoo: 7,
  Other: 8,
};


export const REMINDER_EMAIL_TEMPLATE = {
  QuoteSentTriggerPoint: 23,
  QuoteViewedTriggerPoint: 24,
  QuoteAcceptedTriggerPoint: 25,
  QuoteDeclinedTriggerPoint: 26,
  ContractSentTriggerPoint: 27,
  ContractViewedTriggerPoint: 28,
  ContractSignedTriggerPoint: 29,
  ContractDeclinedTriggerPoint: 30,

};

export const MarketingEmailAddressIdType = {
  EmailAddressID: "EmailAddressID",
  SubscriptionPackageID: "SubscriptionPackageID"
}
export const AppSettingType = {
  UnpaidUser: "Unpaid_Users_Login_To_Outbooks_Warning_Mail",
  paidUser: "Paid_Users_Login_To_Outbooks_Warning_Mail"
}