
// const QuotationSendTriggerPoint = ["QuoteSendTrigger"
// ];
// const QuotationViewTriggerPoint = ["QuotationViewTriggerPoint"
// ];
// const QuotationAcceptedTriggerPoint = ["QuotationAcceptedTriggerPoint"
// ];
// const QuotationDeclinedTriggerPoint = ["QuotationDeclinedTriggerPoint"
// ];
// const EngagementLetterSendTriggerPoint = ["EngagementLetterSendTriggerPoint"
// ];
// const EngagementLetterViewTriggerPoint = ["EngagementLetterViewTriggerPoint"
// ];
// const EngagementLetterDeclinedTriggerPoint = ["EngagementLetterDeclinedTriggerPoint"
// ];
// const EngagementLetterSignedTriggerPoint = ["EngagementLetterSignedTriggerPoint"
// ];
const ReminderTypesProspectVariable = [
    "$AllProspect.FullName$",
    "$Prospect.FirstName$",
    "$Prospect.LastName$",
    "$Prospect.FullName$",
    "$Prospect.Email$",
    "$Prospect.Phone$",
    "$Prospect.Address$",
    "$Prospect.AddressWithLineBreak$",
    "$Prospect.TradingName$",
    "$Prospect.TradingAddress$",
    "$Prospect.TradingAddressWithLineBreak$",
]
const ReminderTypesAccountantVariable = [
    "$AllAccountant.FullName$",
    "$Accountant.FirstName$",
    "$Accountant.LastName$",
    "$Accountant.FullName$",
    "$Accountant.Email$",
    "$Accountant.Phone$",
    "$Accountant.Address$",
    "$Accountant.AddressWithLineBreak$",
    "$Accountant.TradingName$",
    "$Accountant.TradingAddress$",
    "$Accountant.TradingAddressWithLineBreak$",
    "$Accountant.SignatoryName$",
    "$Accountant.SignatureImageUrl$",
    "$Accountant.BusinessTagline$",
    "$Accountant.WebsiteOfAffiliatedAccountingBodyName$",
    "$Accountant.LogoUrl$",
    "$Accountant.AffiliatedAccountingBodyName$",
    "$Accountant.CountryIncorporatedIn$",
    "$Accountant.BrandColor$",
    "$Accountant.Website$",
    "$DocumentId$",
    "$ProposalRef$",
    "$EngagementLetterRef$",
]
export default { ReminderTypesProspectVariable, ReminderTypesAccountantVariable }
